import React from "react";
import {Link, NavLink} from "react-router-dom";
import withRouter, {RouterProp} from "script/with-router";

interface MyLinkProps {
	isNav?: boolean;
	onClick?: (e: MouseEvent) => void;
	to: string;
	children: React.ReactNode;
}

class MyLink extends React.Component<MyLinkProps & RouterProp & Record<string, any>, {}> {
	private readonly linkRef: React.RefObject<HTMLAnchorElement>;

	public constructor(props: MyLinkProps & RouterProp & Record<string, any>) {
		super(props);
		this.linkRef = React.createRef();
	}

	public componentDidMount() {
		if (!this.linkRef.current) {return;}
		this.linkRef.current.addEventListener("click", () => {
			if (this.props.router.location.pathname == this.props.to) {
				window.scrollTo({top: 0});
			} else {
				window.scrollTo({top: 0, behavior: "instant" as ScrollBehavior}); // "instant" is valid but TS 4 doesn't know it
			}
		});
		if (this.props.onClick) {
			this.linkRef.current.addEventListener("click", this.props.onClick);
		}
	}

	public render() {
		const {isNav, onClick, to, ref, ...miscProps} = this.props;
		return this.props.isNav ? (
			<NavLink to={to} {...miscProps} ref={this.linkRef}>{this.props.children}</NavLink>
		) : (
			<Link to={to} {...miscProps} ref={this.linkRef}>{this.props.children}</Link>
		);
	}
}

export default withRouter(MyLink);
