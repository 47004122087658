import React, {Suspense} from "react";
import {Outlet} from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import LoaderPage from "./LoaderPage";

class Page extends React.Component<{}, {}> {
	public render() {
		return (
			<div>
				<Header />
				<Suspense fallback={<LoaderPage />}>
					<Outlet />
				</Suspense>
				<hr className="topHr" />
				<Footer />
			</div>
		);
	}
}

export default Page;
