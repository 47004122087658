import React from "react";
import {Location, NavigateFunction, Params, useLocation, useNavigate, useParams,} from "react-router-dom";

export interface RouterProp {
	router: {
		location: Location,
		navigate: NavigateFunction,
		params: Readonly<Params<string>>
	};
}

export default function withRouter<P>(Component: React.ComponentType<P & RouterProp>): React.ComponentType<P> {
	function ComponentWithRouterProp(props: P) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component {...props} router={{location, navigate, params}} />
		);
	}

	return ComponentWithRouterProp;
}
