export interface CategoryData {
	id: string;
	name: string;
	src: string;
	desc?: string;
	wide?: true;
}

export interface ArtData {
	src: string;
	title: string;
	category: string;
	approx?: true;
	width: number;
	height: number;
	year: number;
	medium: string;
	status?: ArtStatus;
	wide?: true;
}

export enum ArtStatus {
	NOT_FOR_SALE = "nfs",
	SOLD = "sold"
}
