import React from "react";
import setTitle from "script/set-title";
import {FontAwesomeIcon as FA} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

class LoaderPage extends React.Component<{}, {}> {
	public constructor(props: {}) {
		super(props);
		setTitle("Loading…");
	}

	public render() {
		return (<main className="loader"><FA icon={faSpinner} spinPulse size="lg" /> Loading…</main>);
	}
}

export default LoaderPage;
