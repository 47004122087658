import React from "react";
import MyLink from "./MyLink";
import setTitle from "script/set-title";

class ErrorPage extends React.Component<{}, {}> {
	public constructor(props: {}) {
		super(props);
		setTitle("No such page");
	}

	public render() {
		return (<main>This page doesn't exist. <MyLink to="/">Go home?</MyLink></main>);
	}
}

export default ErrorPage;
